<template>
  <card>
    <div slot="header" class="row align-items-center">
      <div class="col-8">
        <h3 class="mb-0">Lista produktów przypisanych</h3>
      </div>
    </div>
    <ul>
      <router-link :to="`/product/${product.id}`" v-for="product in products" :key="product.id">
        <li class="row mb-2 product" :class="product.active ? '' : 'product-inactive' " >
        <div class="col-3 col-md-3 d-flex align-center justify-center">
          <img :src="getImgUrl(product)" alt="..." class="img-fluid product-img">
        </div>
        <div class="col-9 col-md-9">
          <h4 class="mb-0">{{ product.name }}</h4>
          <h4 class="mb-0 text-muted">{{ ( product.grossPrice / 100 ).toFixed(2) }} zł</h4>
          <p class="text-sm text-muted mb-0">
            {{ product.active ? 'Aktywny' : 'Nieaktywny'}}
          </p>
        </div>
        </li>
      </router-link>
    </ul>
  </card>
</template>

<script>
export default {
  name: "GenericCardProductList",
  props: {
    products: {
      type: Array,
      required: true
    }
  },
  methods: {
    getImgUrl(product) {
      return product.image?.thumbUrl ?? '';
    }
  }
}
</script>

<style scoped>
.product {
  border-bottom: 1px solid #e9ecef;
  padding: 10px 0;
}
.product-inactive {
  opacity: 0.5;
}
.product-img {
  max-height: 120px;
  object-fit: contain;
}
</style>
