<template>
  <v-md-editor
    left-toolbar="undo redo link bold  italic"
    right-toolbar="preview"
    mode="edit"
    v-model="cValue"
  ></v-md-editor>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    }
  },
  computed: {
    cValue: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
};
</script>

<style lang="scss">
.v-md-textarea-editor {
  pre, textarea {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 300;
    font-size: 13px;
  }
}
.v-md-editor-preview .vuepress-markdown-body {
  p {
    font-weight: 400;
    font-size: 13px !important;
  }
  padding: 20px;
}
</style>

