<template>
  <div>
    <base-header class="pb-6 mb-4" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
    </base-header>

    <div class="container-fluid" :class="[ cLoading ]">
      <base-alert :type="alertType" v-if="alertType">{{ alertMessage }}</base-alert>
      <modal :show="modalActive">
        <template #header>{{ modalMessage }}</template>
        <div class="d-flex flex-wrap justify-content-between">
          <button class="btn btn-outline-primary" type="button" @click="handleModalClose">Nie</button>
          <button class="btn btn-primary" type="button" @click="handleModalConfirm">Tak</button>
        </div>
      </modal>

      <form @submit.prevent="handleLineSave">
        <div class="row">
          <div class="col-md-5">
            <card>
              <div slot="header" class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">{{ cLineHeader }}</h3>
                </div>
              </div>

              <base-input
                label="Nazwa"
                v-model="cName"
              />
            </card>
          </div>
          <div class="col-md-7">
            <GenericDescriptionForm :form-data="form">
              Opisy linii produktowej
            </GenericDescriptionForm>
          </div>
        </div>
        <div class="row">
          <div class="col-md-5">
            <generic-seo-form :form-data="form">
              Ustawienia SEO
            </generic-seo-form>
          </div>
          <div class="col-md-7">
            <GenericCardProductList :products="products" v-if="lineId"/>
          </div>
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <div
            v-if="lineId"
            class="btn btn-danger"
            @click="modalActive = true"
          >
            Usuń linię
          </div>
          <button class="btn btn-primary" type="submit">
            Zapisz
          </button>
        </div>
    </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import GenericDescriptionForm from "@/views/Store/Generic/GenericDescriptionForm.vue";
import GenericSeoForm from "@/views/Store/Generic/GenericSeoForm.vue";
import GenericCardProductList from "@/views/Store/Generic/GenericCardProductList.vue";

export default {
  name: 'ProductLineManage',
  components: {
    GenericCardProductList,
    GenericSeoForm,
    GenericDescriptionForm,
    BreadcrumbHeader
  },
  data() {
    return {
      modalActive: false,
      modalMessage: 'Czy na pewno chcesz usunąć tę linię produktową?',
    }
  },
  computed: {
    ...mapState('ProductLineManageModule', ['form', 'products', 'name', 'loading', 'alertType', 'alertMessage' ]),
    cLoading() {
      return this.loading ? 'loading' : false;
    },
    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Linie', link: {name: 'product-line-list'}},
        {title: this.lineId ? this.cName : 'Nowa linia' }
      ];
    },
    cName: {
      get() {
        return this.form.name
      },
      set(value) {
        this.updateForm({
          field: 'name',
          value
        })
      }
    },
    lineId() {
      return this.$route.params.id
    },
    cLineHeader() {
      return this.lineId ? 'Edycja linii produktowej' : 'Dodawanie nowej linii produktowej'
    }
  },
  methods: {
    ...mapMutations('ProductLineManageModule', ['updateState', 'updateForm']),
    ...mapActions('ProductLineManageModule', ['getLineInfo', 'getLineProducts', 'handleLineSave', 'handleLineDelete']),
    handleModalClose() {
      this.modalActive = false;
    },
    async handleModalConfirm() {
      this.modalActive = false;
      await this.handleLineDelete();
      await this.$router.push('/product-lines');
    },
    async handleRemount() {
      ['alertMessage', 'alertType'].forEach( field => this.updateState({
        field,
        value: null
      }))
      if ( this.lineId ) {
        await this.getLineInfo(this.lineId)
        await this.getLineProducts(this.lineId)
      }
    }
  },
  watch: {
    async $route(newValue, oldValue) {
      if( newValue.path !== oldValue.path ) {
        await this.handleRemount()
      }
    }
  },
  async beforeMount() {
    await this.handleRemount()
  },
  beforeDestroy() {
    ['name', 'id'].forEach( field => this.updateState({
      field,
      value: null
    }))
  }
};
</script>
<style lang="scss">
.loading {
  opacity: 0.5;
  pointer-events: none;
}
</style>
